import styled from "styled-components";

import { Body1, Body2, H1, SubTitle } from "./Typography";
import HIIcon from "../icons/LogoIcon";
import HelpIcon from "../icons/HelpIcon";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
  [role="img"] {
    font-size: 4rem;
  }
  ul {
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    list-style: none;

    li {
      margin-left: 1rem;
      display: flex;
      align-items: center;

      :before {
        content: "•";
        margin-right: 0.5rem;
      }
    }
  }

  ${Body1} {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }
  }
`;

const SubTitle1 = styled(SubTitle)`
  color: #000000;
  font-weight: 600;
`;

const Failure = () => {
  return (
    <Container>
      <HIIcon width={32} height={32} />
      <H1>
        <FormattedMessage id="Deeplink.Failure.Title" />
      </H1>
      <SubTitle1>
        <FormattedMessage id="Deeplink.Failure.SubTitle" />
      </SubTitle1>
      <ul>
        <Body1>
          <HelpIcon />
          <FormattedMessage id="Deeplink.Failure.List1Title" />
        </Body1>
        <li>
          <Body2>
            <FormattedMessage id="Deeplink.Failure.List1Option1" />
          </Body2>
        </li>
        <li>
          <Body2>
            <FormattedMessage id="Deeplink.Failure.List1Option2" />
          </Body2>
        </li>
      </ul>

      <ul>
        <Body1>
          <HelpIcon />
          <FormattedMessage id="Deeplink.Failure.List2Title" />
        </Body1>
        <li>
          <Body2>
            <FormattedMessage id="Deeplink.Failure.List2Option1" />
          </Body2>
        </li>
        <li>
          <Body2>
            <FormattedMessage id="Deeplink.Failure.List2Option2" />
          </Body2>
        </li>
      </ul>

      <Body2>
        <FormattedMessage id="Deeplink.Failure.Footer" />
      </Body2>
    </Container>
  );
};

export default Failure;
