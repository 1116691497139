import { useEffect, useState } from "react";
import { IntlProvider, FormattedMessage } from "react-intl";
import styled, { createGlobalStyle } from "styled-components";
import customProtocolCheck from "custom-protocol-check";

import messages from "./i18n";
import HILoader from "./components/HILoader";
import Success from "./components/Success";
import Failure from "./components/Failure";
import { Bold, H1, SubTitle } from "./components/Typography";

const GlobalStyle = createGlobalStyle`  
  body, html{
    height: 100vh;
    font-family: "Segoe UI", serif;
    margin: 0;
  }
  
  #root {
    height: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin: auto;
  height: 100%;

  > ${SubTitle} {
    text-align: center;
  }
`;

const getLanguage = () =>
  (navigator.languages?.[0] || navigator.language || "en-GB")
    .split("-")[0]
    .toLowerCase();

const DeeplinkContainer = () => {
  const [hasHI, setHasHI] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const { pathname, search, hash } = window.location;
    const qs = new URLSearchParams(search);
    qs.append("ts", Date.now().toString());
    const url = `hicoach://${pathname.slice(1)}?${qs.toString()}${hash}`;
    customProtocolCheck(
      url,
      () => {
        setIsLoading(false);
        setHasHI(false);
      },
      () => {
        setIsLoading(false);
        setHasHI(true);
      },
      5000
    );
  }, []);

  return (
    <Container>
      {isLoading && (
        <>
          <HILoader />
          <H1>
            <FormattedMessage id="Deeplink.Loader.Title" />
          </H1>
          <SubTitle>
            <FormattedMessage
              id="Deeplink.Loader.SubTitle"
              values={{ b: (value) => <Bold>{value}</Bold> }}
            />
          </SubTitle>
        </>
      )}
      {hasHI === false && <Failure />}
      {hasHI === true && <Success />}
    </Container>
  );
};

const App = () => (
  <IntlProvider
    messages={messages[getLanguage()] || messages.en}
    locale={getLanguage()}
    defaultLocale="en"
  >
    <DeeplinkContainer />
    <GlobalStyle />
  </IntlProvider>
);

export default App;
