import styled from "styled-components";

import { H1, SubTitle } from "./Typography";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
  text-align: center;
  [role="img"] {
    font-size: 4rem;
  }
`;

const Success = () => {
  return (
    <Container>
      <span role="img" aria-label="success">
        🎉
      </span>
      <H1>
        <FormattedMessage id="Deeplink.Success.Title" />
      </H1>
      <SubTitle>
        <FormattedMessage id="Deeplink.Success.SubTitle1" />
        <br />
        <FormattedMessage id="Deeplink.Success.SubTitle2" />
      </SubTitle>
    </Container>
  );
};

export default Success;
