import styled from "styled-components";

const Svg = styled.svg`
  min-width: ${({ width }) => width}px;
  min-height: ${({ height }) => height}px;
`;

const HelpIcon = ({ width = 14, height = 14 } = {}) => (
  <Svg
    preserveAspectRatio="none"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 .333A6.67 6.67 0 0 0 .333 7 6.669 6.669 0 0 0 7 13.667 6.669 6.669 0 0 0 13.667 7 6.67 6.67 0 0 0 7 .333Zm0 12A5.34 5.34 0 0 1 1.667 7 5.34 5.34 0 0 1 7 1.667 5.34 5.34 0 0 1 12.334 7 5.34 5.34 0 0 1 7 12.333Zm-.667-2.666h1.334V11H6.334V9.667Zm1.074-6.64a2.668 2.668 0 0 0-2.954 1.86c-.12.386.174.78.58.78h.134c.273 0 .493-.194.587-.447.213-.593.846-1 1.533-.853.633.133 1.1.753 1.047 1.4-.067.893-1.08 1.086-1.634 1.92 0 .006-.006.006-.006.013-.007.013-.014.02-.02.033-.06.1-.12.214-.167.334-.007.02-.02.033-.027.053-.007.013-.007.027-.013.047-.08.226-.133.5-.133.833h1.333c0-.28.073-.513.187-.713.013-.02.02-.04.033-.06.053-.094.12-.18.186-.26.007-.007.014-.02.02-.027.067-.08.14-.153.22-.227.64-.606 1.507-1.1 1.327-2.373-.16-1.16-1.073-2.14-2.233-2.313Z"
      fill="#94f1b2"
    />
  </Svg>
);

export default HelpIcon;
