import en from "./en-GB.json";
import it from "./it-IT.json";
import fr from "./fr-FR.json";

const messages = {
  en: en,
  it: it,
  fr: fr,
};
export default messages;
