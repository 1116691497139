import styled from "styled-components";

const Bold = styled.span`
  font-weight: bold;
`;

const H1 = styled.div`
  color: #000000;
  font-size: 3rem;
  font-weight: bold;
  line-height: 150%;
  margin: 20px 0;
`;

const SubTitle = styled.div`
  color: #626262;
  font-size: 1.375rem;
  line-height: 150%;
`;

const Body1 = styled.span`
  color: #626262;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
`;

const Body2 = styled.span`
  color: #626262;
  font-size: 0.825rem;
  line-height: 1.3125rem;
`;

export { Bold, H1, SubTitle, Body1, Body2 };
