import styled, { keyframes } from "styled-components";
import HIIcon from "../icons/LogoIcon";

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderSpinner = styled.div`
  position: relative;
  width: ${({ size }) => size || 200}px;
  height: ${({ size }) => size || 200}px;

  > div {
    width: ${({ size }) => (size || 200) - 2 * Math.ceil((size || 200) / 25)}px;
    height: ${({ size }) =>
      (size || 200) - 2 * Math.ceil((size || 200) / 25)}px;
    position: absolute;
    border-radius: 50%;
    border: ${({ size }) => Math.ceil((size || 200) / 25)}px solid;
    border-color: #e6444d transparent transparent transparent;
    animation: ${Spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  & > *:nth-child(1) {
    animation-delay: -0.3s;
  }
  & > *:nth-child(2) {
    animation-delay: -0.2s;
  }
  & > *:nth-child(3) {
    animation-delay: -0.1s;
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  margin: 50px;

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const HILoader = () => (
  <LoaderContainer>
    <LoaderSpinner>
      <div />
      <div />
      <div />
    </LoaderSpinner>
    <HIIcon />
  </LoaderContainer>
);

export default HILoader;
