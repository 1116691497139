const HIIcon = ({ width = 64, height = 64 } = {}) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <rect width="64" height="64" rx="18" fill="#241751" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.093 23.233c1.012 0 1.862-.347 2.551-1.042.718-.71 1.076-1.553 1.076-2.532a3.487 3.487 0 0 0-1.076-2.574C45.927 16.362 45.077 16 44.093 16c-.97 0-1.807.362-2.51 1.085-.69.695-1.034 1.553-1.034 2.574 0 .993.345 1.837 1.034 2.532.689.695 1.525 1.042 2.51 1.042ZM21.694 47.72V36.615c0-1.73.507-3.113 1.519-4.149 1.04-1.05 2.264-1.574 3.67-1.574 1.279 0 2.274.355 2.984 1.064.71.709 1.065 1.751 1.065 3.127V47.72h5.715V33.04c0-2.453-.703-4.304-2.109-5.552-1.42-1.262-3.31-1.893-5.673-1.893a9.714 9.714 0 0 0-4.07.893c-1.308.596-2.341 1.383-3.1 2.362V16.34H16v31.38h5.694Zm25.288-21.53v21.53h-5.695V26.19h5.695Z"
      fill="#94f1b2"
    />
  </svg>
);

export default HIIcon;
